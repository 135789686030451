import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const IndexPage = () => {
  useEffect(() => {
    setTimeout(() => {
      const sliderReadyEvent = new CustomEvent('SliderReady')
      document.dispatchEvent(sliderReadyEvent)
    }, 800)
  }, [])
  const slideComponent = (
    <div className="tp-banner-container">
      <div className="tp-banner2">
        <ul>
          <li
            data-transition="slidehorizontal"
            data-slotamount="7"
            data-masterspeed="1000"
            data-fstransition="fade"
            data-fsmasterspeed="1000"
            data-fsslotamount="7"
          >
            <img
              data-x="0"
              data-y="0"
              data-bgposition="center top"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              src="/img/slide/slides/code.webp"
            />

            <div
              className="tp-caption large_bold_white tp-fade fadeout tp-resizeme"
              data-x="30"
              data-y="120"
              data-voffset="0"
              data-speed="500"
              data-start="1000"
              data-easing="Power4.easeOut"
              data-splitin="chars"
              data-splitout="chars"
              data-elementdelay="0.05"
              data-endelementdelay="0.05"
              data-endspeed="300"
              style={{
                zIndex: 3,
                maxWidth: 'auto',
                maxHeight: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              Moderne Mobile und Web Apps
            </div>

            <div
              className="tp-caption medium_light_white tp-fade fadeout tp-resizeme"
              data-x="30"
              data-y="195"
              data-voffset="120"
              data-customin="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
              data-customout="x:0;y:0;z:0;rotationX:0;rotationY:0;rotationZ:0;scaleX:0;scaleY:0;skewX:0;skewY:0;opacity:0;transformPerspective:600;transformOrigin:50% 50%;"
              data-speed="500"
              data-start="1800"
              data-easing="Power3.easeInOut"
              data-splitin="words"
              data-splitout="words"
              data-elementdelay="0.12"
              data-endelementdelay="0.12"
              data-endspeed="300"
              style={{
                zIndex: 5,
                maxWidth: 'auto',
                maxHeight: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              Design, Entwicklung und Betrieb aus einer Hand
            </div>
          </li>

          <li
            data-transition="zoomout"
            data-slotamount="7"
            data-masterspeed="1000"
          >
            <img
              src="/img/slide/slides/flutter-multi-device.webp"
              alt="slidebg1"
              data-bgfit="contain"
              data-bgposition="center"
              data-bgrepeat="no-repeat"
            />

            <div
              className="tp-caption large_bold_white large_text lft tp-resizeme"
              data-x="right"
              data-y="10"
              data-hoffset="-60"
              data-voffset="0"
              data-speed="500"
              data-start="1000"
              data-easing="Power4.easeOut"
              data-splitin="chars"
              data-splitout="chars"
              data-elementdelay="0.05"
              data-endelementdelay="0.05"
              data-endspeed="300"
              style={{
                zIndex: 3,
                maxWidth: 'auto',
                maxHeight: 'auto',
                whiteSpace: 'nowrap',
                color: 'black',
              }}
            >
              We use Flutter
            </div>

            <div
              className="tp-caption medium_light_white large_text lft tp-resizeme"
              data-x="right"
              data-y="80"
              data-voffset="120"
              data-hoffset="-60"
              data-speed="500"
              data-start="1800"
              data-easing="Power3.easeInOut"
              data-splitin="words"
              data-splitout="words"
              data-elementdelay="0.12"
              data-endelementdelay="0.12"
              data-endspeed="300"
              style={{
                zIndex: 5,
                maxWidth: 'auto',
                maxHeight: 'auto',
                whiteSpace: 'nowrap',
                color: 'grey',
              }}
            >
              Apps für jedes Gerät
            </div>
          </li>

          <li
            data-transition="zoomout"
            data-slotamount="7"
            data-masterspeed="1000"
            data-saveperformance="off"
          >
            <img
              src="/img/slide/slides/devices.webp"
              alt="power-to-creators-slider"
              data-bgposition="center top"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
            />

            <div
              className="tp-caption large_text_dark lft"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="-100"
              data-speed="500"
              data-start="500"
              data-easing="Power2.easeIn"
              data-splitin="lines"
              data-splitout="none"
              data-elementdelay="0.1"
              data-endelementdelay="0.1"
              data-endspeed="300"
              style={{
                zIndex: 2,
                maxWidth: 'auto',
                maxHeight: 'auto',
                whiteSpace: 'normal',
              }}
            >
              Apps, Desktop und Web
            </div>

            <div
              className="tp-caption small_text_dark lft"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="-30"
              data-speed="300"
              data-start="1100"
              data-easing="Power2.easeIn"
              data-splitin="lines"
              data-splitout="none"
              data-elementdelay="0.1"
              data-endelementdelay="0.1"
              data-endspeed="300"
              style={{
                zIndex: 3,
                fontSize: '16px',
                maxWidth: 'auto',
                maxHeight: 'auto',
                whiteSpace: 'normal',
              }}
            >
              Wir bringen Ihre Anwendungen direkt in die Stores und helfen beim
              erfolgreichen Betrieb.
            </div>

            <div
              className="tp-caption style-caption lfb"
              data-x="center"
              data-y="center"
              data-voffset="30"
              data-speed="300"
              data-start="1600"
              data-easing="Power3.easeInOut"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.1"
              data-endelementdelay="0.1"
              data-endspeed="300"
            >
              <span className="button">
                <span>
                  <i className="fa fa-desktop"></i>
                </span>
                Desktop & Web
              </span>
            </div>

            <div
              className="tp-caption style-caption lfb"
              data-x="center"
              data-y="center"
              data-voffset="130"
              data-speed="300"
              data-start="1600"
              data-easing="Power3.easeInOut"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.1"
              data-endelementdelay="0.1"
              data-endspeed="300"
            >
              <span className="button">
                <span>
                  <i className="fa fa-android"></i>
                </span>
                Android App
              </span>
            </div>

            <div
              className="tp-caption style-caption fb"
              data-x="center"
              data-y="center"
              data-voffset="230"
              data-speed="300"
              data-start="1600"
              data-easing="Power3.easeInOut"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.1"
              data-endelementdelay="0.1"
              data-endspeed="300"
            >
              <span className="button">
                <span>
                  <i className="fa fa-apple"></i>
                </span>
                iOS App
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
  return (
    <Layout slideComponent={slideComponent} isSlider={true}>
      <Seo title="Home" />
      <div className="row info_title wow fadeInUp">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical animated">
          <div className="headline">Wir bauen IHRE Mobile oder Web App</div>
          <p className="">
            Von der erster Idee bis zum fertigen Produkt: Moinsen.DEV ist für
            Sie da.
          </p>
        </div>
        <div className="vertical_line"></div>

        <i className="fa fa-cogs right"></i>
      </div>

      <div className="row info_title">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical text-center">
          <div className="headline">
            Das ist <span>Moinsen</span>
          </div>
        </div>
      </div>

      <section className="content_info">
        <div className="paddings">
          <div className="container">
            <div className="row">

              <div className="col-sm-12 col-md-12">
                <div className="item-team">
                  <img src="/img/team/uli.webp" alt="" />
                  <h4>
                    Uli Diedrichsen
                    <span>
                      Business Development, Produktion und Architektur
                    </span>
                  </h4>
                  <p>
                    Seit über 30 Jahren im Software-Geschäft und mit Einsätzen
                    rund um den Globus auf vier Kontinenten.
                  </p>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>

      <div className="row info_title wow fadeInUp">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical animated">
          <div className="headline">
            Wir <span>schaffen</span> lieber als lange drum herum zu reden
          </div>
          <p>
            „Wir bringen unsere Projekte durch einen persönlichen und effektiven
            Ansatz vorran, damit Ihr Vorhaben erfolgreich wird.“
          </p>
        </div>
        <div className="vertical_line"></div>

        <i className="fa fa-file-code-o left"></i>
      </div>

      <section className="content_info">
        <div className="info_resalt borders">
          <div className="container">
            <div className="row">
              <div className="col-md-8 wow fadeInLeft">
                <div className="row results">
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                    <i className="fa fa-bomb"></i>
                    <h2>
                      69.760 <span>+</span>
                    </h2>
                    <h5>STUNDEN GEARBEITET SEIT 1992</h5>
                  </div>
                  <div className="col-md-2">
                    <i className="fa fa-group"></i>
                    <h2>
                      61 <span>+</span>
                    </h2>
                    <h5>KUNDEN</h5>
                  </div>
                  <div className="col-md-2">
                    <i className="fa fa-coffee"></i>
                    <h2>
                      112 <span>=</span>
                    </h2>
                    <h5>PROJEKTE ABGESCHLOSSEN</h5>
                  </div>
                  <div className="col-md-2">
                    <i className="fa fa-briefcase"></i>
                    <h2>40</h2>
                    <h5>JAHRE ERFAHRUNG</h5>
                  </div>
                  <span className="arrow_results"></span>
                </div>
              </div>

              <div className="col-md-4 wow fadeInRight">
                <img
                  src="/img/home/moinsen_mac_small.webp"
                  alt=""
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row info_title wow fadeInUp">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical animated">
          <div className="headline">
            Bullshit-Bingo? Wir <span>coden</span> lieber!
          </div>
          <p>
            „Eine moderne App zu entwickeln erfordert kein Gewinner im
            Bullshit-Bingo, sondern unsere Erfahrung, Leidenschaft und
            Zuverlässigkeit.“
          </p>
        </div>
        <div className="vertical_line"></div>

        <i className="fa fa-mobile right"></i>
      </div>

      <section className="content_info">
        <section className="info_resalt borders">
          <div className="container wow fadeInUp">
            <div className="row text-center">
              <div className="service-process">
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="thumbnail">
                    <div className="caption-head">
                      <em className="caption-icon fa fa-graduation-cap icon-big"></em>
                      <h2 className="caption-title">Konzept</h2>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="thumbnail">
                    <div className="caption-head">
                      <em className="caption-icon fa fa-edit icon-big"></em>
                      <h2 className="caption-title">Architektur</h2>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="thumbnail">
                    <div className="caption-head">
                      <em className="caption-icon fa fa-cog icon-big"></em>
                      <h2 className="caption-title">Produktion</h2>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="thumbnail">
                    <div className="caption-head">
                      <em className="caption-icon fa fa-plane icon-big"></em>
                      <h2 className="caption-title">Launch</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 single_services">
                <p className="lead">
                  Wir halten die Anzahl gleichzeitiger Projekte gering, um
                  unseren Kunden den best möglichen Service zu bieten.&nbsp;
                  <Link to="/projects/">Mehr erfahren</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="row info_title wow fadeInUp">
        <div className="vertical_line">
          <div className="circle_bottom"></div>
        </div>
        <div className="info_vertical animated">
          <div className="headline">
            Internet ist nur ein <span>Hype</span>
          </div>
          <p>„Bill Gates, 1993“</p>
        </div>
        <div className="vertical_line"></div>

        <i className="fa fa-comment left"></i>
      </div>
    </Layout>
  )
}

export default IndexPage
